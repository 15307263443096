import '../sass/project.scss';

document.addEventListener('DOMContentLoaded', () => {
  initializeUserMenu();
  initializeClassFilters();
});

function initializeUserMenu() {
  const menuButton = document.getElementById('user-menu-button');
  if (!menuButton) {
    console.error('Element with ID "user-menu-button" not found.');
    return;
  }

  const dropdownMenu = menuButton.parentElement.nextElementSibling;
  if (!dropdownMenu) {
    console.error('Dropdown menu not found.');
    return;
  }

  menuButton.addEventListener('click', () => {
    dropdownMenu.style.display = dropdownMenu.style.display === 'none' ? 'block' : 'none';
  });
}

function initializeClassFilters() {
  // Only proceed if we're on a page with the class filters
  const filterContainer = document.querySelector('fieldset:nth-of-type(2)');
  if (!filterContainer) {
    // Exit quietly if we're not on a page with class filters
    return;
  }

  const filters = {
    location: null,
    day: null,
  };

  // Get required elements with null checks
  const enrollmentForm = document.querySelector('form');
  const dayFieldset = document.querySelector('fieldset:nth-of-type(2)');
  const classAssignedSelect = document.querySelector('#class_assigned');

  // Exit if required elements are not found
  if (!enrollmentForm || !dayFieldset || !classAssignedSelect) {
    console.warn('Required elements for class filters not found');
    return;
  }

  // Hide the form initially
  enrollmentForm.style.display = 'none';

  // Hide day selection initially
  dayFieldset.style.display = 'none';

  // Create reset button
  const resetButton = document.createElement('button');
  resetButton.textContent = 'Reset Selections';
  resetButton.className = 'bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition-colors mt-4';
  resetButton.style.display = 'none'; // Hide initially
  resetButton.addEventListener('click', resetFilters);

  // Add reset button after the filters
  filterContainer.parentElement.insertBefore(resetButton, enrollmentForm);

  // Get all classes with null check
  const allClasses = Array.from(classAssignedSelect.options || []).map(option => {
    const [location, day] = (option.textContent || '').split('-').map(part => part.trim().toLowerCase());
    return {
      element: option,
      location: location.replace(/\n/g, ''),
      day,
    };
  });

  document.querySelectorAll('.filter-radio').forEach(radio => {
    radio.addEventListener('change', event => {
      filters[event.target.name] = event.target.value.toLowerCase();

      // Show reset button when any selection is made
      resetButton.style.display = 'block';

      if (event.target.name === 'location') {
        // Reset day selection when location changes
        filters.day = null;
        document.querySelectorAll('input[name="day"]').forEach(radio => {
          radio.checked = false;
        });
        // Show day selection after location is selected
        dayFieldset.style.display = 'block';
        // Hide form until day is selected
        enrollmentForm.style.display = 'none';
      }

      if (event.target.name === 'day') {
        // Show form when both location and day are selected
        enrollmentForm.style.display = 'block';
      }

      updateDaysFilter();
      filterClasses();
      autoSelectFirstClass();
    });
  });

  function resetFilters() {
    filters.location = null;
    filters.day = null;

    // Uncheck all radio buttons
    document.querySelectorAll('.filter-radio').forEach(radio => {
      radio.checked = false;
    });

    // Reset visibility
    dayFieldset.style.display = 'none';
    enrollmentForm.style.display = 'none';
    resetButton.style.display = 'none';

    // Enable all day options
    document.querySelectorAll('input[name="day"]').forEach(radio => {
      radio.disabled = false;
      const label = document.querySelector(`label[for="${radio.id}"]`);
      if (label) {
        label.style.display = 'inline-block';
      }
    });

    // Show all classes
    allClasses.forEach(cls => {
      cls.element.style.display = 'block';
    });
  }

  function updateDaysFilter() {
    const days = new Set();
    allClasses.forEach(cls => {
      if (!filters.location || cls.location === filters.location) {
        days.add(cls.day);
      }
    });

    document.querySelectorAll('input[name="day"]').forEach(radio => {
      const day = radio.value.toLowerCase();
      const isDayAvailable = days.has(day);
      radio.disabled = !isDayAvailable;
      const label = document.querySelector(`label[for="${radio.id}"]`);
      if (label) {
        label.style.display = isDayAvailable ? 'inline-block' : 'none';
      }
    });
  }

  function filterClasses() {
    allClasses.forEach(cls => {
      const locationMatch = !filters.location || cls.location.includes(filters.location);
      const dayMatch = !filters.day || cls.day.includes(filters.day);
      cls.element.style.display = (locationMatch && dayMatch) ? 'block' : 'none';
    });
  }

  function autoSelectFirstClass() {
    const visibleOptions = allClasses.filter(cls => cls.element.style.display !== 'none');
    if (visibleOptions.length > 0) {
      visibleOptions[0].element.selected = true;
    }
  }
}
